import React from 'react';
import ContactForm from '../components/ContactForm';
import QuoteForm from '../components/QuoteForm';
import mast from '../img/ContactMasthead.jpg';
import formBg from '../img/ContactBG.jpg';
import '../styles/contact.scss';
import BotttomLogoSection from '../components/BottomLogoSection';

import '../styles/ContactTemplate.scss';

const ContactTemplate = (props) => {
	return (
		<main className="ContactTemplate">
			<section className="hero is-light is-bold">
				<div
					className="hero-body"
					style={{
						backgroundImage: `url(${mast})`,
					}}
				>
					<div className="container">
						<h1>
							{props.content.masthead_heading}
						</h1>
					</div>
				</div>
			</section>

			<section className="section" style={{ marginBottom: '-6rem' }}>
				<div className="container">
					<div className="columns">
						<div className="column is-8">
							<h2>
								{props.content.secondary_heading}
								<a className="phonelink" href="tel:17057952218">
											+1(705)&nbsp;795-2218
								</a>
							</h2>
						</div>
					</div>
				</div>
			</section>

			<section
				className="is-inverted is-skewed"
				style={{
					backgroundImage: `url(${formBg})`,
					backgroundPosition: 'center center',
					backgroundSize: 'cover',
				}}
			>
				<div
					className="section"
					style={{
						paddingTop: '16rem',
						paddingBottom: '12rem',
						marginBottom: '-6rem',
					}}
				>
					<div className="container">
						<div className="columns firstContact">
							<div className="column">
								<div className="contactInner">
									<h2>{props.content.contact_heading}</h2>
									<p>
										{props.content.contact_content}
									</p>
								</div>
							</div>
							<div className="column has-text-centered">
								<div className="contactInner">
									<ContactForm />
								</div>
							</div>
						</div>

						<div className="columns secondContact">
							<div className="column">
								<div className="contactInner">
									<h2>{props.content.quote_heading}</h2>
									<p>
										{props.content.quote_content}
									</p>
								</div>
							</div>
							<div className="column has-text-centered">
								<div className="contactInner">
									<QuoteForm />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<BotttomLogoSection />
		</main>
	);
};

export default ContactTemplate;
