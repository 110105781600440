import React from 'react';

const ContactForm = () => (
	<form name="contact" method="post" data-netlify="true" action="/thanks">
		<input type="hidden" name="form-name" value="contact" />
		<div className="control">
			<input
				className="input"
				name="name"
				id="name"
				type="text"
				placeholder="Name"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				name="email"
				id="email"
				type="email"
				placeholder="Email"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				name="phone"
				id="phone"
				type="phone"
				placeholder="Phone"
				required
			/>
		</div>
		<div className="control">
			<textarea
				className="textarea"
				name="message"
				id="message"
				rows="5"
				placeholder="Message"
				required
			/>
		</div>
		<button type="submit" className="is-button">
			SUBMIT
		</button>
	</form>
);

export default ContactForm;
