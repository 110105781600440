import React from 'react';

const QuoteForm = () => (
	<form name="request" method="post" data-netlify="true" action="/thanks">
		<input type="hidden" name="form-name" value="request" />
		<div className="control">
			<input
				className="input"
				name="quote-name"
				id="quote-name"
				type="text"
				placeholder="Name"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				name="quote-email"
				id="quote-email"
				type="email"
				placeholder="Email"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				name="quote-phone"
				id="quote-phone"
				type="phone"
				placeholder="Phone"
				required
			/>
		</div>
		<div className="control">
			<input
				className="input"
				type="text"
				placeholder="Size of Installation Area"
				required
				name="installation-size"
				id="installation-size"
			/>
		</div>
		<div className="control">
			<input
				className="input"
				type="text"
				placeholder="Type of Installation (Residential, Off Grid, Etc)"
				required
				name="installation-type"
				id="installation-type"
			/>
		</div>
		<button type="submit" className="is-button" style={{ marginTop: '2rem' }}>
			SUBMIT
		</button>
	</form>
);

export default QuoteForm;
