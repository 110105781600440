import React from 'react';
import { Link } from 'gatsby';
import footLogo from '../img/Logo.png';

const BotttomLogoSection = () => (
	<section className="section has-text-right bottomLogo">
		<div className="container">
			<Link to="/">
				<img
					src={footLogo}
					alt="GridZero Solar logo"
					style={{ width: '224px' }}
				/>
			</Link>
		</div>
	</section>
);

export default BotttomLogoSection;
